/** @format */

import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import numbro from "numbro";
import { utils } from "ethers";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useSwitchNetwork, useEnsName, useNetwork } from "wagmi";
import Web3 from "web3";
import { PoolABI, PoolContractAddress } from "../Contracts/PoolsABI";
import useBalance from "../Contracts/useBalance";
import useAllowance from "../Contracts/useAllowance";
import { ErcABI, ErcContractAddress } from "../Contracts/Abi";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

const Pool = () => {
  const [selected, setSelected] = useState(1);
  const [selected1, setSelected1] = useState(1);
  const [selected2, setSelected2] = useState(1);
  const [bnbAmount0, setBnbAmount0] = useState(null);
  const [bnbAmount1, setBnbAmount1] = useState(null);
  const [bnbAmount2, setBnbAmount2] = useState(null);
  const [stakeBalance0, setStakeBalance0] = useState(null);
  const [stakeBalance1, setStakeBalance1] = useState(null);
  const [stakeBalance2, setStakeBalance2] = useState(null);
  const [btn, setBtn] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingUn, setloadingUn] = useState(false);

  const { address, isConnected } = useAccount();

  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { chains, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();
  const web3 = new Web3(Web3.givenProvider || "http://localhost:3000/");

  const getFullDisplayBalance = (balance, format = {}) => {
    return numbro(utils.formatEther(balance)).format(Object.assign({}, format));
  };

  const [yBNBbalance] = useBalance(
    "0x99B4D0b0a078AD86B3867C3822895E9705f7d56c",
    18,
  );

  function calcPerc0(amount) {
    setBnbAmount0((yBNBbalance / 100) * amount);
  }
  function calcPerc1(amount) {
    setBnbAmount1((yBNBbalance / 100) * amount);
  }
  function calcPerc2(amount) {
    setBnbAmount2((yBNBbalance / 100) * amount);
  }

  function calcPerc0un(amount) {
    setBnbAmount0((stakeBalance0 / 100) * amount);
  }
  function calcPerc1un(amount) {
    setBnbAmount1((stakeBalance1 / 100) * amount);
  }
  function calcPerc2un(amount) {
    setBnbAmount2((stakeBalance2 / 100) * amount);
  }
  const handleButton = buttonId => {
    setSelected(buttonId);
    setBnbAmount0("");
  };
  const handleButton1 = buttonId => {
    setSelected1(buttonId);
    setBnbAmount1("");
  };
  const handleButton2 = buttonId => {
    setSelected2(buttonId);
    setBnbAmount2("");
  };
  function purchaseSuccessful() {
    setBtn(false);
    toast.success("You have successfully deposit!");
  }

  function purchaseFailed() {
    setBtn(false);
    toast.error("Unable to handle your request, try again!");
  }

  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  //                             0
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  const handleDeposit0 = async () => {
    try {
      console.log(bnbAmount0, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }
      if (!bnbAmount0 || isNaN(bnbAmount0) || bnbAmount0 <= 0) {
        setBtn(false);
        return toast.error("Amount should be a valid number greater than 0");
      }
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();

      const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);
      const invest = await contract.methods
        .deposit(web3.utils.toWei(String(bnbAmount0), "ether"), 0)
        .send({
          from: address,
          // value: web3.utils.toWei(String(bnbAmount0), "ether"),
          // gas: 1000000,
          gasPrice: rpcGasPrice,
        });

      if (invest) {
        purchaseSuccessful();
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };

  const handleUnStake0 = async () => {
    if (address) {
      if (bnbAmount0 != "" && bnbAmount0 != null && bnbAmount0 != 0) {
        try {
          setloadingUn(true);
          const rpcGasPrice = await web3.eth.getGasPrice();

          const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

          const withdraw = contract.methods
            .withdraw(web3.utils.toWei(String(bnbAmount0), "ether"), 0)
            .send({
              from: address,
              // gas: 1000000,
              gasPrice: rpcGasPrice,
            })
            .then(res => {
              setloadingUn(false);
              toast.success("Successfully UnStake");
              setBnbAmount0("");
            })
            .catch(er => {
              setloadingUn(false);
              setBnbAmount0("");
              toast.error(
                "Something went wrong, you can ask in telegram group about this issue",
              );
            });
        } catch (error) {
          setBtn(false);
          // console.log(5, error);
        }
      } else {
        setloadingUn(false);
        toast.error("Amount should not be empty");
      }
    } else {
      setloadingUn(false);
      toast.info(
        "Unable to detect any wallet, please connect metamask or trust wallet",
      );
    }
  };

  const handleHarvest0 = async () => {
    setloading(true);
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

    const harvest = contract.methods
      .harvest()
      .send({
        from: address,
        value: web3.utils.toWei("0", "ether"),
        // gas: 1000000,
        gasPrice: rpcGasPrice,
      })
      .then(res => {
        setloading(false);
        toast.success("Successfully Harvest");
      })
      .catch(er => {
        setloading(false);
        toast.error(
          "Something went wrong, you can ask in telegram group about this issue",
        );
      });
  };
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  //                               1
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  const handleDeposit1 = async () => {
    try {
      console.log(bnbAmount1, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }
      if (!bnbAmount1 || isNaN(bnbAmount1) || bnbAmount1 <= 0) {
        setBtn(false);
        return toast.error("Amount should be a valid number greater than 0");
      }
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();

      const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);
      const invest = await contract.methods
        .deposit(web3.utils.toWei(String(bnbAmount1), "ether"), 1)
        .send({
          from: address,
          // value: web3.utils.toWei(String(bnbAmount0), "ether"),
          // gas: 1000000,
          gasPrice: rpcGasPrice,
        });

      if (invest) {
        purchaseSuccessful();
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };

  const handleUnStake1 = async () => {
    if (address) {
      if (bnbAmount1 != "" && bnbAmount1 != null && bnbAmount1 != 0) {
        try {
          setloadingUn(true);
          const rpcGasPrice = await web3.eth.getGasPrice();

          const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

          const withdraw = contract.methods
            .withdraw(web3.utils.toWei(String(bnbAmount1), "ether"), 1)
            .send({
              from: address,
              // gas: 1000000,
              gasPrice: rpcGasPrice,
            })
            .then(res => {
              setloadingUn(false);
              toast.success("Successfully withdraw");
              setBnbAmount1("");
            })
            .catch(er => {
              setloadingUn(false);
              setBnbAmount1("");
              toast.error(
                "Something went wrong, you can ask in telegram group about this issue",
              );
            });
        } catch (error) {
          setBtn(false);
          // console.log(5, error);
        }
      } else {
        setloadingUn(false);
        toast.error("Amount should not be empty");
      }
    } else {
      setloadingUn(false);
      toast.info(
        "Unable to detect any wallet, please connect metamask or trust wallet",
      );
    }
  };

  const handleHarvest1 = async () => {
    setloading(true);
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

    const harvest = contract.methods
      .harvest()
      .send({
        from: address,
        value: web3.utils.toWei("0", "ether"),
        // gas: 1000000,
        gasPrice: rpcGasPrice,
      })
      .then(res => {
        setloading(false);
        toast.success("Successfully Harvest");
      })
      .catch(er => {
        setloading(false);
        toast.error(
          "Something went wrong, you can ask in telegram group about this issue",
        );
      });
  };

  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  //                             2
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  const handleDeposit2 = async () => {
    try {
      console.log(bnbAmount2, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }
      if (!bnbAmount2 || isNaN(bnbAmount2) || bnbAmount2 <= 0) {
        setBtn(false);
        return toast.error("Amount should be a valid number greater than 0");
      }
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();

      const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);
      const invest = await contract.methods
        .deposit(web3.utils.toWei(String(bnbAmount2), "ether"), 2)
        .send({
          from: address,
          // value: web3.utils.toWei(String(bnbAmount0), "ether"),
          // gas: 1000000,
          gasPrice: rpcGasPrice,
        });

      if (invest) {
        purchaseSuccessful();
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };

  const handleUnStake2 = async () => {
    if (address) {
      if (bnbAmount2 != "" && bnbAmount2 != null && bnbAmount2 != 0) {
        try {
          setloadingUn(true);
          const rpcGasPrice = await web3.eth.getGasPrice();

          const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

          const withdraw = contract.methods
            .withdraw(web3.utils.toWei(String(bnbAmount2), "ether"), 2)
            .send({
              from: address,
              // gas: 1000000,
              gasPrice: rpcGasPrice,
            })
            .then(res => {
              setloadingUn(false);
              toast.success("Successfully withdraw");
              setBnbAmount2("");
            })
            .catch(er => {
              setloadingUn(false);
              setBnbAmount2("");
              toast.error(
                "Something went wrong, you can ask in telegram group about this issue",
              );
            });
        } catch (error) {
          setBtn(false);
          // console.log(5, error);
        }
      } else {
        setloadingUn(false);
        toast.error("Amount should not be empty");
      }
    } else {
      setloadingUn(false);
      toast.info(
        "Unable to detect any wallet, please connect metamask or trust wallet",
      );
    }
  };

  const handleHarvest2 = async () => {
    setloading(true);
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

    const harvest = contract.methods
      .harvest()
      .send({
        from: address,
        value: web3.utils.toWei("0", "ether"),
        // gas: 1000000,
        gasPrice: rpcGasPrice,
      })
      .then(res => {
        setloading(false);
        toast.success("Successfully Harvest");
      })
      .catch(er => {
        setloading(false);
        toast.error(
          "Something went wrong, you can ask in telegram group about this issue",
        );
      });
  };

  let allowanceWeb3 = useAllowance(ErcContractAddress, PoolContractAddress);
  const [allowance, setAllowance] = useState("0");
  const MAX_UINT =
    "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  //                             approve
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  useEffect(() => {
    setAllowance(allowanceWeb3);
  }, [allowanceWeb3]);

  const approve = async () => {
    try {
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();
      const contract = new web3.eth.Contract(ErcABI, ErcContractAddress);
      const approvalTransaction = await contract?.methods
        .approve(PoolContractAddress, MAX_UINT)
        .send({
          from: address,
          value: web3.utils.toWei("0", "ether"),
          // gas: 1000000,
          gasPrice: rpcGasPrice,
        });
      if (approvalTransaction) {
        toast.success("Approval successful");
        console.log("Approval==>", approvalTransaction);
        setAllowance(MAX_UINT);
        setBtn(false);
      } else {
        toast.error("Approval transaction failed");
      }
    } catch (error) {
      console.error("Error during approval:", error);
      setBtn(false);
    }
  };

  const handleUserInfo0 = async () => {
    const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

    const userInfo = contract.methods
      .userInfo(0, address)
      .call()
      .then(res => {
        // console.log(res);
        setStakeBalance0(getFullDisplayBalance(res?.amount));
      })
      .catch(er => {});
  };
  const handleUserInfo1 = async () => {
    const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

    const userInfo = contract.methods
      .userInfo(1, address)
      .call()
      .then(res => {
        // console.log(res);
        setStakeBalance1(getFullDisplayBalance(res?.amount));
      })
      .catch(er => {});
  };
  const handleUserInfo2 = async () => {
    const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

    const userInfo = contract.methods
      .userInfo(2, address)
      .call()
      .then(res => {
        // console.log(res);
        setStakeBalance2(getFullDisplayBalance(res?.amount));
      })
      .catch(er => {});
  };
  useEffect(() => {
    if (chain?.id === 56) {
      handleUserInfo0();
      handleUserInfo1();
      handleUserInfo2();
      const interval = setInterval(() => {
        handleUserInfo0();
        handleUserInfo1();
        handleUserInfo2();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [address]);
  React.useEffect(() => {
    document.title = "L2reum - Trenches Pools";
  }, []);
  return (
    <div className=' w-full relative'>
      <div className='row'>
        <div className='flex switch justify-center gap-7 mb-4'>
          <Link to='/'>
            {" "}
            <button>
              LRT Staking
              <div id='clip'>
                <div id='leftTop' className='corner' />
                <div id='rightBottom' className='corner' />
                <div id='rightTop' className='corner' />
                <div id='leftBottom' className='corner' />
              </div>
              <span id='rightArrow' className='arrow' />
              <span id='leftArrow' className='arrow' />
            </button>
          </Link>{" "}
          <Link to='/pool'>
            {" "}
            <button>
              Trenches Pools
              <div id='clip'>
                <div id='leftTop' className='corner' />
                <div id='rightBottom' className='corner' />
                <div id='rightTop' className='corner' />
                <div id='leftBottom' className='corner' />
              </div>
              <span id='rightArrow' className='arrow' />
              <span id='leftArrow' className='arrow' />
            </button>
          </Link>
        </div>
      </div>
      <section className=' text-gray-100'>
        <div className='  mx-auto'>
          <div className='grid gap-4 py-4  sm:grid-cols-12 max-w-6xl m-auto'>
            <div className='col-span-12 sm:col-span-4'>
              <div className='text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-[#e2107c]'>
                <h3 className='text-3xl font-semibold'>Trenches Pools</h3>
                <span className='text-sm font-bold   text-gray-400'>
                  Stake yBNB to Earn High APY
                </span>
              </div>
            </div>
            <div className='relative col-span-12  space-y-6 sm:col-span-8'>
              <div className='col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-700'>
                <div className='flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-42px] sm:before:z-[1] before:bg-[#e2107c]'>
                  <div className='card_main1  gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]   mt-4 m-auto  px-8 py-12 sm:px-12 lg:px-0'>
                    <h3 className='typography-brand-heading-1 max-w-[16ch] m-auto animate-appear-slide-up text-white relative text-center'>
                      Senior Trench
                    </h3>
                    <div className='  flex typography-brand-heading-3  text-gray-300 items-center justify-around my-10'>
                      <h3 className=' block  font-medium text-white text-2xl'>
                        APY{" "}
                      </h3>
                      <h3 className=' block font-bold text-2xl typography-brand-heading-3  text-yellow-300 '>
                        14%{" "}
                      </h3>
                    </div>

                    {isConnected ? (
                      chain?.id === 56 ? (
                        allowance === "0" ? (
                          <div
                            onClick={approve}
                            className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                                  {btn ? (
                                    <>
                                      <div
                                        class='spinner-border text-light spinner-border-sm'
                                        role='status'>
                                        <span class='sr-only'>Loading...</span>
                                      </div>
                                      &nbsp; Processing transaction...
                                    </>
                                  ) : (
                                    <span className='mx-5 space-x-3'>
                                      Approve
                                    </span>
                                  )}
                                </div>
                              </div>
                            </span>
                          </div>
                        ) : (
                          <div>
                            <div className='flex items-center flex-col-wrap m-auto mt-5'>
                              <div
                                className={`btn_buy  absolute left-0 ${
                                  selected === 1
                                    ? "selected transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]"
                                    : "not-selected bg-[#3017488e]"
                                }`}
                                onClick={() => handleButton(1)}>
                                Deposit{" "}
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  fill='currentColor'
                                  className='bi bi-plus'
                                  viewBox='0 0 16 16'>
                                  <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                                </svg>
                              </div>
                              <div
                                className={`btn_sell absolute right-0 ${
                                  selected === 2
                                    ? "selected transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]"
                                    : "not-selected bg-[#3017488e]"
                                }`}
                                onClick={() => handleButton(2)}>
                                Withdraw{" "}
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  fill='currentColor'
                                  className='bi bi-dash'
                                  viewBox='0 0 16 16'>
                                  <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8' />
                                </svg>
                              </div>
                            </div>
                            {selected === 1 ? (
                              <React.Fragment>
                                <div className='my-5'>
                                  <div class='flex items-center rounded-md  border-1 border-pink-500   '>
                                    <NumberFormat
                                      value={bnbAmount0 || ""}
                                      onChange={e =>
                                        setBnbAmount0(e.target.value)
                                      }
                                      allowNegative={false}
                                      thousandSeparator
                                      isNumericString
                                      placeholder='0.0'
                                      className={`flex-1 p-2 border-r border-pink-500 text-2xl text-white bg-transparent `}
                                    />
                                    <span className='p-[12px] flex items-center'>
                                      <button
                                        onClick={() => calcPerc0(100)}
                                        className=' select-none disabled:cursor-not-allowed rounded  py-2 px-1  text-center align-middle font-para text-xs font-bold uppercase text-gray-100 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                                        type='button'>
                                        Max
                                      </button>
                                    </span>
                                  </div>

                                  <h5 className='text-sm w-full  text-center m-auto mt-1 text-white'>
                                    Balance:{" "}
                                    {yBNBbalance
                                      ? parseFloat(yBNBbalance).toFixed(3) +
                                        " yBNB"
                                      : "0"}
                                  </h5>
                                </div>
                                <div className='text-center'>
                                  <button
                                    onClick={() => {
                                      handleDeposit0();
                                    }}
                                    disabled={btn}
                                    className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                                    <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                      <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                                        {btn ? (
                                          <>
                                            <div
                                              class='spinner-border text-light spinner-border-sm'
                                              role='status'>
                                              <span class='sr-only'>
                                                Loading...
                                              </span>
                                            </div>
                                            &nbsp; Processing transaction...
                                          </>
                                        ) : (
                                          <span className='mx-5 space-x-3'>
                                            Deposit
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <div className='my-5'>
                                  <div class='flex items-center rounded-md  border-1 border-pink-500   '>
                                    <NumberFormat
                                      value={bnbAmount0 || ""}
                                      onChange={e =>
                                        setBnbAmount0(e.target.value)
                                      }
                                      allowNegative={false}
                                      thousandSeparator
                                      isNumericString
                                      placeholder='0.0'
                                      className={`flex-1 p-2 border-r border-pink-500 text-2xl text-white bg-transparent `}
                                    />
                                    <span className='p-[12px] flex items-center'>
                                      <button
                                        onClick={() => calcPerc0un(100)}
                                        className=' select-none disabled:cursor-not-allowed rounded  py-2 px-1  text-center align-middle font-para text-xs font-bold uppercase text-gray-100 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                                        type='button'>
                                        Max
                                      </button>
                                    </span>
                                  </div>

                                  <h5 className='text-sm w-full  text-center m-auto mt-1 text-white'>
                                    Balance:{" "}
                                    {stakeBalance0
                                      ? parseFloat(stakeBalance0).toFixed(3) +
                                        " yBNB"
                                      : "0"}
                                  </h5>
                                </div>

                                <div className='text-center'>
                                  <button
                                    onClick={() => {
                                      handleUnStake0();
                                    }}
                                    disabled={loadingUn}
                                    className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                                    <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                      <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                                        {loadingUn ? (
                                          <>
                                            <div
                                              class='spinner-border text-light spinner-border-sm'
                                              role='status'>
                                              <span class='sr-only'>
                                                Loading...
                                              </span>
                                            </div>
                                            &nbsp; Processing transaction...
                                          </>
                                        ) : (
                                          <span className='mx-5 space-x-3'>
                                            Withdraw
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        )
                      ) : (
                        <div className='text-center'>
                          {chains.map(x => (
                            <button
                              onClick={() => switchNetwork?.(x.id)}
                              disabled={!switchNetwork || x.id === chain?.id}
                              key={x.id}
                              className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                              <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                                  Switch {x.name} &nbsp;
                                  {isLoading && pendingChainId === x.id && (
                                    <div
                                      class='spinner-border text-light spinner-border-sm'
                                      role='status'>
                                      <span class='sr-only'>Loading...</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </button>
                          ))}
                        </div>
                      )
                    ) : (
                      <div
                        onClick={open}
                        className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                        <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                          <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                            <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                              Connect Wallet
                            </div>
                          </div>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-42px] sm:before:z-[1] before:bg-[#e2107c]'>
                  <div className='card_main1  gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]   mt-4 m-auto  px-8 py-12 sm:px-12 lg:px-0'>
                    <h3 className='typography-brand-heading-1 max-w-[16ch] m-auto animate-appear-slide-up text-white relative text-center'>
                      Mezzanine Trench
                    </h3>
                    <div className='  flex typography-brand-heading-3  text-gray-300 items-center justify-around my-10'>
                      <h3 className=' block  font-medium text-white text-2xl'>
                        APY{" "}
                      </h3>
                      <h3 className=' block font-bold text-2xl typography-brand-heading-3  text-yellow-300 '>
                        14%{" "}
                      </h3>
                    </div>

                    {isConnected ? (
                      chain?.id === 56 ? (
                        allowance === "0" ? (
                          <div
                            onClick={approve}
                            className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                                  {btn ? (
                                    <>
                                      <div
                                        class='spinner-border text-light spinner-border-sm'
                                        role='status'>
                                        <span class='sr-only'>Loading...</span>
                                      </div>
                                      &nbsp; Processing transaction...
                                    </>
                                  ) : (
                                    <span className='mx-5 space-x-3'>
                                      Approve
                                    </span>
                                  )}
                                </div>
                              </div>
                            </span>
                          </div>
                        ) : (
                          <div>
                            <div className='flex items-center m-auto mt-5'>
                              <div
                                className={`btn_buy absolute left-0 ${
                                  selected1 === 1
                                    ? "selected transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]"
                                    : "not-selected bg-[#3017488e]"
                                }`}
                                onClick={() => handleButton1(1)}>
                                Deposit{" "}
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  fill='currentColor'
                                  className='bi bi-plus'
                                  viewBox='0 0 16 16'>
                                  <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                                </svg>
                              </div>
                              <div
                                className={`btn_sell absolute right-0 ${
                                  selected1 === 2
                                    ? "selected transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]"
                                    : "not-selected bg-[#3017488e]"
                                }`}
                                onClick={() => handleButton1(2)}>
                                Withdraw{" "}
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  fill='currentColor'
                                  className='bi bi-dash'
                                  viewBox='0 0 16 16'>
                                  <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8' />
                                </svg>
                              </div>
                            </div>
                            {selected1 === 1 ? (
                              <React.Fragment>
                                <div className='my-5'>
                                  <div class='flex items-center rounded-md  border-1 border-pink-500   '>
                                    <NumberFormat
                                      value={bnbAmount1 || ""}
                                      onChange={e =>
                                        setBnbAmount1(e.target.value)
                                      }
                                      allowNegative={false}
                                      thousandSeparator
                                      isNumericString
                                      placeholder='0.0'
                                      className={`flex-1 p-2 border-r border-pink-500 text-2xl text-white bg-transparent `}
                                    />
                                    <span className='p-[12px] flex items-center'>
                                      <button
                                        onClick={() => calcPerc1(100)}
                                        className=' select-none disabled:cursor-not-allowed rounded  py-2 px-1  text-center align-middle font-para text-xs font-bold uppercase text-gray-100 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                                        type='button'>
                                        Max
                                      </button>
                                    </span>
                                  </div>

                                  <h5 className='text-sm w-full  text-center m-auto mt-1 text-white'>
                                    Balance:{" "}
                                    {yBNBbalance
                                      ? parseFloat(yBNBbalance).toFixed(3) +
                                        " yBNB"
                                      : "0"}
                                  </h5>
                                </div>
                                <div className='text-center'>
                                  <button
                                    onClick={() => {
                                      handleDeposit1();
                                    }}
                                    disabled={btn}
                                    className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                                    <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                      <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                                        {btn ? (
                                          <>
                                            <div
                                              class='spinner-border text-light spinner-border-sm'
                                              role='status'>
                                              <span class='sr-only'>
                                                Loading...
                                              </span>
                                            </div>
                                            &nbsp; Processing transaction...
                                          </>
                                        ) : (
                                          <span className='mx-5 space-x-3'>
                                            Deposit
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <div className='my-5'>
                                  <div class='flex items-center rounded-md  border-1 border-pink-500   '>
                                    <NumberFormat
                                      value={bnbAmount1 || ""}
                                      onChange={e =>
                                        setBnbAmount1(e.target.value)
                                      }
                                      allowNegative={false}
                                      thousandSeparator
                                      isNumericString
                                      placeholder='0.0'
                                      className={`flex-1 p-2 border-r border-pink-500 text-2xl text-white bg-transparent `}
                                    />
                                    <span className='p-[12px] flex items-center'>
                                      <button
                                        onClick={() => calcPerc1un(100)}
                                        className=' select-none disabled:cursor-not-allowed rounded  py-2 px-1  text-center align-middle font-para text-xs font-bold uppercase text-gray-100 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                                        type='button'>
                                        Max
                                      </button>
                                    </span>
                                  </div>

                                  <h5 className='text-sm w-full  text-center m-auto mt-1 text-white'>
                                    Balance:{" "}
                                    {stakeBalance1
                                      ? parseFloat(stakeBalance1).toFixed(3) +
                                        " yBNB"
                                      : "0"}
                                  </h5>
                                </div>
                                <div className='text-center'>
                                  <button
                                    onClick={() => {
                                      handleUnStake1();
                                    }}
                                    disabled={loadingUn}
                                    className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                                    <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                      <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                                        {loadingUn ? (
                                          <>
                                            <div
                                              class='spinner-border text-light spinner-border-sm'
                                              role='status'>
                                              <span class='sr-only'>
                                                Loading...
                                              </span>
                                            </div>
                                            &nbsp; Processing transaction...
                                          </>
                                        ) : (
                                          <span className='mx-5 space-x-3'>
                                            Withdraw
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        )
                      ) : (
                        <div className='text-center'>
                          {chains.map(x => (
                            <button
                              onClick={() => switchNetwork?.(x.id)}
                              disabled={!switchNetwork || x.id === chain?.id}
                              key={x.id}
                              className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                              <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                                  Switch {x.name} &nbsp;
                                  {isLoading && pendingChainId === x.id && (
                                    <div
                                      class='spinner-border text-light spinner-border-sm'
                                      role='status'>
                                      <span class='sr-only'>Loading...</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </button>
                          ))}
                        </div>
                      )
                    ) : (
                      <div
                        onClick={open}
                        className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                        <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                          <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                            <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                              Connect Wallet
                            </div>
                          </div>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-42px] sm:before:z-[1] before:bg-[#e2107c]'>
                  <div className='card_main1  gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]   mt-4 m-auto  px-8 py-12 sm:px-12 lg:px-3'>
                    <h3 className='typography-brand-heading-1 max-w-[16ch] m-auto animate-appear-slide-up text-white relative text-center'>
                      Junior Trench
                    </h3>
                    <div className='  flex typography-brand-heading-3  text-gray-300 items-center justify-around my-10'>
                      <h3 className=' block  font-medium text-white text-2xl'>
                        APY{" "}
                      </h3>
                      <h3 className=' block font-bold text-2xl typography-brand-heading-3  text-yellow-300 '>
                        14%{" "}
                      </h3>
                    </div>

                    {isConnected ? (
                      chain?.id === 56 ? (
                        allowance === "0" ? (
                          <div
                            onClick={approve}
                            className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                                  {btn ? (
                                    <>
                                      <div
                                        class='spinner-border text-light spinner-border-sm'
                                        role='status'>
                                        <span class='sr-only'>Loading...</span>
                                      </div>
                                      &nbsp; Processing transaction...
                                    </>
                                  ) : (
                                    <span className='mx-5 space-x-3'>
                                      Approve
                                    </span>
                                  )}
                                </div>
                              </div>
                            </span>
                          </div>
                        ) : (
                          <div>
                            <div className='flex items-center m-auto mt-5'>
                              <div
                                className={`btn_buy  absolute left-0 ${
                                  selected2 === 1
                                    ? "selected transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]"
                                    : "not-selected bg-[#3017488e]"
                                }`}
                                onClick={() => handleButton2(1)}>
                                Deposit{" "}
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  fill='currentColor'
                                  className='bi bi-plus'
                                  viewBox='0 0 16 16'>
                                  <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                                </svg>
                              </div>
                              <div
                                className={`btn_sell absolute right-0 ${
                                  selected2 === 2
                                    ? "selected transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]"
                                    : "not-selected bg-[#3017488e]"
                                }`}
                                onClick={() => handleButton2(2)}>
                                Withdraw{" "}
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  fill='currentColor'
                                  className='bi bi-dash'
                                  viewBox='0 0 16 16'>
                                  <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8' />
                                </svg>
                              </div>
                            </div>
                            {selected2 === 1 ? (
                              <React.Fragment>
                                <div className='my-5'>
                                  <div class='flex items-center rounded-md  border-1 border-pink-500   '>
                                    <NumberFormat
                                      value={bnbAmount2 || ""}
                                      onChange={e =>
                                        setBnbAmount2(e.target.value)
                                      }
                                      allowNegative={false}
                                      thousandSeparator
                                      isNumericString
                                      placeholder='0.0'
                                      className={`flex-1 p-2 border-r border-pink-500 text-2xl text-white bg-transparent `}
                                    />
                                    <span className='p-[12px] flex items-center'>
                                      <button
                                        onClick={() => calcPerc2(100)}
                                        className=' select-none disabled:cursor-not-allowed rounded  py-2 px-1  text-center align-middle font-para text-xs font-bold uppercase text-gray-100 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                                        type='button'>
                                        Max
                                      </button>
                                    </span>
                                  </div>

                                  <h5 className='text-sm w-full  text-center m-auto mt-1 text-white'>
                                    Balance:{" "}
                                    {yBNBbalance
                                      ? parseFloat(yBNBbalance).toFixed(3) +
                                        " yBNB"
                                      : "0"}
                                  </h5>
                                </div>
                                <div className='text-center'>
                                  <button
                                    onClick={() => {
                                      handleDeposit2();
                                    }}
                                    disabled={btn}
                                    className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                                    <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                      <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                                        {btn ? (
                                          <>
                                            <div
                                              class='spinner-border text-light spinner-border-sm'
                                              role='status'>
                                              <span class='sr-only'>
                                                Loading...
                                              </span>
                                            </div>
                                            &nbsp; Processing transaction...
                                          </>
                                        ) : (
                                          <span className='mx-5 space-x-3'>
                                            Deposit
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <div className='my-5'>
                                  <div class='flex items-center rounded-md  border-1 border-pink-500   '>
                                    <NumberFormat
                                      value={bnbAmount2 || ""}
                                      onChange={e =>
                                        setBnbAmount2(e.target.value)
                                      }
                                      allowNegative={false}
                                      thousandSeparator
                                      isNumericString
                                      placeholder='0.0'
                                      className={`flex-1 p-2 border-r border-pink-500 text-2xl text-white bg-transparent `}
                                    />
                                    <span className='p-[12px] flex items-center'>
                                      <button
                                        onClick={() => calcPerc2un(100)}
                                        className=' select-none disabled:cursor-not-allowed rounded  py-2 px-1  text-center align-middle font-para text-xs font-bold uppercase text-gray-100 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                                        type='button'>
                                        Max
                                      </button>
                                    </span>
                                  </div>

                                  <h5 className='text-sm w-full  text-center m-auto mt-1 text-white'>
                                    Balance:{" "}
                                    <strong>
                                      {" "}
                                      {stakeBalance2
                                        ? parseFloat(stakeBalance2).toFixed(3) +
                                          " yBNB"
                                        : "0"}
                                    </strong>
                                  </h5>
                                </div>
                                <div className='text-center'>
                                  <button
                                    onClick={() => {
                                      handleUnStake2();
                                    }}
                                    disabled={loadingUn}
                                    className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                                    <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                      <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                                        {loadingUn ? (
                                          <>
                                            <div
                                              class='spinner-border text-light spinner-border-sm'
                                              role='status'>
                                              <span class='sr-only'>
                                                Loading...
                                              </span>
                                            </div>
                                            &nbsp; Processing transaction...
                                          </>
                                        ) : (
                                          <span className='mx-5 space-x-3'>
                                            Withdraw
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        )
                      ) : (
                        <div className='text-center'>
                          {chains.map(x => (
                            <button
                              onClick={() => switchNetwork?.(x.id)}
                              disabled={!switchNetwork || x.id === chain?.id}
                              key={x.id}
                              className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                              <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                                <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                                  Switch {x.name} &nbsp;
                                  {isLoading && pendingChainId === x.id && (
                                    <div
                                      class='spinner-border text-light spinner-border-sm'
                                      role='status'>
                                      <span class='sr-only'>Loading...</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </button>
                          ))}
                        </div>
                      )
                    ) : (
                      <div
                        onClick={open}
                        className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                        <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                          <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                            <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                              Connect Wallet
                            </div>
                          </div>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className='container m-auto px-6 text-gray-500 md:px-10 xl:px-0'>
        <div className='grid gap-6  lg:w-full lg:grid-cols-1'>
          <div className='card_main1  gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]   mt-4 m-auto  px-8 py-12 sm:px-12 lg:px-3'>
            <h3 className='typography-brand-heading-1 max-w-[16ch] m-auto animate-appear-slide-up text-white relative text-center'>
              Senior Trench
            </h3>
            <div className='  flex  items-center justify-around my-10'>
              <h3 className=' block  font-medium text-black text-2xl'>APY </h3>
              <h3 className=' block font-bold text-2xl'>14% </h3>
            </div>

            {isConnected ? (
              chain?.id === 56 ? (
                allowance === "0" ? (
                  <div
                    onClick={approve}
                    className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                    <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                      <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                        <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                          {btn ? (
                            <>
                              <div
                                class='spinner-border text-light spinner-border-sm'
                                role='status'>
                                <span class='sr-only'>Loading...</span>
                              </div>
                              &nbsp; Processing transaction...
                            </>
                          ) : (
                            <span className='mx-5 space-x-3'>Approve</span>
                          )}
                        </div>
                      </div>
                    </span>
                  </div>
                ) : (
                  <div>
                    <div className='flex items-center flex-col-wrap m-auto mt-5'>
                      <div
                        className={`btn_buy ${
                          selected === 1
                            ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                            : "not-selected bg-[#c1c1c7]"
                        }`}
                        onClick={() => handleButton(1)}>
                        Deposit
                      </div>
                      <div
                        className={`btn_sell ${
                          selected === 2
                            ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                            : "not-selected bg-[#c1c1c7]"
                        }`}
                        onClick={() => handleButton(2)}>
                        Withdraw
                      </div>
                    </div>
                    {selected === 1 ? (
                      <React.Fragment>
                        <div className='my-5'>
                          <div className='relative flex w-full max-w-[24rem] m-auto '>
                            <div className='relative h-12 w-full min-w-[200px] '>
                              <input
                                type='number'
                                className='peer h-full w-full rounded-[7px]  borde font-head border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                                placeholder=' '
                                value={bnbAmount0 || ""}
                                onChange={e => setBnbAmount0(e.target.value)}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Enter Amount
                              </label>
                            </div>
                            <button
                              onClick={() => calcPerc0(100)}
                              className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                              type='button'>
                              Max
                            </button>
                          </div>
                          <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                            Balance:{" "}
                            <strong>
                              {" "}
                              {yBNBbalance
                                ? parseFloat(yBNBbalance).toFixed(3) + " yBNB"
                                : "0"}
                            </strong>
                          </h5>
                        </div>
                        <div className='text-center'>
                          <button
                            onClick={() => {
                              handleDeposit0();
                            }}
                            disabled={btn}
                            className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              {btn ? (
                                <>
                                  <div
                                    class='spinner-border text-light spinner-border-sm'
                                    role='status'>
                                    <span class='sr-only'>Loading...</span>
                                  </div>
                                  &nbsp; Processing transaction...
                                </>
                              ) : (
                                <span className='mx-5 space-x-3'>Deposit</span>
                              )}
                            </span>
                          </button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className='my-5'>
                          <div className='relative flex w-full max-w-[24rem] m-auto '>
                            <div className='relative h-12 w-full min-w-[200px]'>
                              <input
                                type='number'
                                className='peer h-full w-full rounded-[7px]  borde font-head border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                                placeholder=' '
                                value={bnbAmount0 || ""}
                                onChange={e => setBnbAmount0(e.target.value)}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Enter Amount
                              </label>
                            </div>
                            <button
                              onClick={() => calcPerc0un(100)}
                              className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                              type='button'>
                              Max
                            </button>
                          </div>
                          <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                            Balance:{" "}
                            <strong>
                              {" "}
                              {stakeBalance0
                                ? parseFloat(stakeBalance0).toFixed(3) + " yBNB"
                                : "0"}
                            </strong>
                          </h5>
                        </div>

                        <div className='text-center'>
                          <button
                            onClick={() => {
                              handleUnStake0();
                            }}
                            disabled={loadingUn}
                            className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              {loadingUn ? (
                                <>
                                  <div
                                    class='spinner-border text-light spinner-border-sm'
                                    role='status'>
                                    <span class='sr-only'>Loading...</span>
                                  </div>
                                  &nbsp; Processing transaction...
                                </>
                              ) : (
                                <span className='mx-5 space-x-3'>Withdraw</span>
                              )}
                            </span>
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                )
              ) : (
                <div className='text-center'>
                  {chains.map(x => (
                    <button
                      disabled={!switchNetwork || x.id === chain?.id}
                      key={x.id}
                      className='flex max-w-4xl btn font-para text-lg m-auto mt-5 border-0 fw-bold items-center  font-para py-3 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
                      onClick={() => switchNetwork?.(x.id)}>
                      Switch {x.name} &nbsp;
                      {isLoading && pendingChainId === x.id && (
                        <div
                          class='spinner-border text-light spinner-border-sm'
                          role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                      )}
                    </button>
                  ))}
                </div>
              )
            ) : (
              <div
                onClick={open}
                className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                  <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                    <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                      Connect Wallet
                    </div>
                  </div>
                </span>
              </div>
            )}
          </div>
          <div className='card_main1  gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]   mt-4 m-auto  px-8 py-12 sm:px-12 lg:px-3'>
            <h3 className='typography-brand-heading-1 max-w-[16ch] m-auto animate-appear-slide-up text-white relative text-center'>
              Mezzanine Trench
            </h3>
            <div className='  flex  items-center justify-around my-10'>
              <h3 className='font-head block font-medium text-black text-2xl'>
                APY{" "}
              </h3>
              <h3 className='font-head block font-bold text-2xl'>32% </h3>
            </div>

            {isConnected ? (
              chain?.id === 56 ? (
                allowance === "0" ? (
                  <div
                  onClick={approve}
                  className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                  <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                    <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                      <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                        {btn ? (
                          <>
                            <div
                              class='spinner-border text-light spinner-border-sm'
                              role='status'>
                              <span class='sr-only'>Loading...</span>
                            </div>
                            &nbsp; Processing transaction...
                          </>
                        ) : (
                          <span className='mx-5 space-x-3'>Approve</span>
                        )}
                      </div>
                    </div>
                  </span>
                </div>
                ) : (
                  <div>
                    <div className='flex items-center m-auto mt-5'>
                      <div
                        className={`btn_buy ${
                          selected1 === 1
                            ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                            : "not-selected bg-[#c1c1c7]"
                        }`}
                        onClick={() => handleButton1(1)}>
                        Deposit
                      </div>
                      <div
                        className={`btn_sell ${
                          selected1 === 2
                            ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                            : "not-selected bg-[#c1c1c7]"
                        }`}
                        onClick={() => handleButton1(2)}>
                        Withdraw
                      </div>
                    </div>
                    {selected1 === 1 ? (
                      <React.Fragment>
                        <div className='my-5'>
                          <div className='relative flex w-full max-w-[24rem] m-auto '>
                            <div className='relative h-12 w-full min-w-[200px] '>
                              <input
                                type='number'
                                className='peer h-full w-full rounded-[7px]  borde font-head border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                                placeholder=' '
                                value={bnbAmount1 || ""}
                                onChange={e => setBnbAmount1(e.target.value)}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Enter Amount
                              </label>
                            </div>
                            <button
                              onClick={() => calcPerc1(100)}
                              className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                              type='button'>
                              Max
                            </button>
                          </div>
                          <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                            Balance:{" "}
                            <strong>
                              {" "}
                              {yBNBbalance
                                ? parseFloat(yBNBbalance).toFixed(3) + " yBNB"
                                : "0"}
                            </strong>
                          </h5>
                        </div>
                        <div className='text-center'>
                          <button
                            onClick={() => {
                              handleDeposit1();
                            }}
                            disabled={btn}
                            className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              {btn ? (
                                <>
                                  <div
                                    class='spinner-border text-light spinner-border-sm'
                                    role='status'>
                                    <span class='sr-only'>Loading...</span>
                                  </div>
                                  &nbsp; Processing transaction...
                                </>
                              ) : (
                                <span className='mx-5 space-x-3'>Deposit</span>
                              )}
                            </span>
                          </button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className='my-5'>
                          <div className='relative flex w-full max-w-[24rem] m-auto '>
                            <div className='relative h-12 w-full min-w-[200px]'>
                              <input
                                type='number'
                                className='peer h-full w-full rounded-[7px]  borde font-head border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                                placeholder=' '
                                value={bnbAmount1 || ""}
                                onChange={e => setBnbAmount1(e.target.value)}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Enter Amount
                              </label>
                            </div>
                            <button
                              onClick={() => calcPerc1un(100)}
                              className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                              type='button'>
                              Max
                            </button>
                          </div>
                          <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                            Balance:{" "}
                            <strong>
                              {" "}
                              {stakeBalance1
                                ? parseFloat(stakeBalance1).toFixed(3) + " yBNB"
                                : "0"}
                            </strong>
                          </h5>
                        </div>
                        <div className='text-center'>
                          <button
                            onClick={() => {
                              handleUnStake0();
                            }}
                            disabled={loadingUn}
                            className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              {loadingUn ? (
                                <>
                                  <div
                                    class='spinner-border text-light spinner-border-sm'
                                    role='status'>
                                    <span class='sr-only'>Loading...</span>
                                  </div>
                                  &nbsp; Processing transaction...
                                </>
                              ) : (
                                <span className='mx-5 space-x-3'>Withdraw</span>
                              )}
                            </span>
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                )
              ) : (
                <div className='text-center'>
                  {chains.map(x => (
                    <button
                      disabled={!switchNetwork || x.id === chain?.id}
                      key={x.id}
                      className='flex max-w-4xl btn font-para text-lg m-auto mt-5 border-0 fw-bold items-center  font-para py-3 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
                      onClick={() => switchNetwork?.(x.id)}>
                      Switch {x.name} &nbsp;
                      {isLoading && pendingChainId === x.id && (
                        <div
                          class='spinner-border text-light spinner-border-sm'
                          role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                      )}
                    </button>
                  ))}
                </div>
              )
            ) : (
              <div
                onClick={open}
                className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                  <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                    <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                      Connect Wallet
                    </div>
                  </div>
                </span>
              </div>
            )}
          </div>
          <div className='card_main1  gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]   mt-4 m-auto  px-8 py-12 sm:px-12 lg:px-3'>
            <h3 className='typography-brand-heading-1 max-w-[16ch] m-auto animate-appear-slide-up text-white relative text-center'>
              Junior Trench
            </h3>
            <div className='  flex  items-center justify-around my-10'>
              <h3 className='font-head block font-medium text-black text-2xl'>
                APY{" "}
              </h3>
              <h3 className='font-head block font-bold text-2xl'>56% </h3>
            </div>

            {isConnected ? (
              chain?.id === 56 ? (
                allowance === "0" ? (
                  <div
                  onClick={approve}
                  className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                  <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                    <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                      <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                        {btn ? (
                          <>
                            <div
                              class='spinner-border text-light spinner-border-sm'
                              role='status'>
                              <span class='sr-only'>Loading...</span>
                            </div>
                            &nbsp; Processing transaction...
                          </>
                        ) : (
                          <span className='mx-5 space-x-3'>Approve</span>
                        )}
                      </div>
                    </div>
                  </span>
                </div>
                ) : (
                  <div>
                    <div className='flex items-center m-auto mt-5'>
                      <div
                        className={`btn_buy ${
                          selected2 === 1
                            ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                            : "not-selected bg-[#c1c1c7]"
                        }`}
                        onClick={() => handleButton2(1)}>
                        Deposit
                      </div>
                      <div
                        className={`btn_sell ${
                          selected2 === 2
                            ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                            : "not-selected bg-[#c1c1c7]"
                        }`}
                        onClick={() => handleButton2(2)}>
                        Withdraw
                      </div>
                    </div>
                    {selected2 === 1 ? (
                      <React.Fragment>
                        <div className='my-5'>
                          <div className='relative flex w-full max-w-[24rem] m-auto '>
                            <div className='relative h-12 w-full min-w-[200px] '>
                              <input
                                type='number'
                                className='peer h-full w-full rounded-[7px]  borde font-head border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                                placeholder=' '
                                value={bnbAmount2 || ""}
                                onChange={e => setBnbAmount2(e.target.value)}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Enter Amount
                              </label>
                            </div>
                            <button
                              onClick={() => calcPerc2(100)}
                              className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                              type='button'>
                              Max
                            </button>
                          </div>
                          <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                            Balance:{" "}
                            <strong>
                              {" "}
                              {yBNBbalance
                                ? parseFloat(yBNBbalance).toFixed(3) + " yBNB"
                                : "0"}
                            </strong>
                          </h5>
                        </div>
                        <div className='text-center'>
                          <button
                            onClick={() => {
                              handleDeposit2();
                            }}
                            disabled={btn}
                            className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              {btn ? (
                                <>
                                  <div
                                    class='spinner-border text-light spinner-border-sm'
                                    role='status'>
                                    <span class='sr-only'>Loading...</span>
                                  </div>
                                  &nbsp; Processing transaction...
                                </>
                              ) : (
                                <span className='mx-5 space-x-3'>Deposit</span>
                              )}
                            </span>
                          </button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className='my-5'>
                          <div className='relative flex w-full max-w-[24rem] m-auto '>
                            <div className='relative h-12 w-full min-w-[200px]'>
                              <input
                                type='number'
                                className='peer h-full w-full rounded-[7px]  borde font-head border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                                placeholder=' '
                                value={bnbAmount2 || ""}
                                onChange={e => setBnbAmount2(e.target.value)}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Enter Amount
                              </label>
                            </div>
                            <button
                              onClick={() => calcPerc2un(100)}
                              className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                              type='button'>
                              Max
                            </button>
                          </div>
                          <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                            Balance:{" "}
                            <strong>
                              {" "}
                              {stakeBalance2
                                ? parseFloat(stakeBalance2).toFixed(3) + " yBNB"
                                : "0"}
                            </strong>
                          </h5>
                        </div>
                        <div className='text-center'>
                          <button
                            onClick={() => {
                              handleUnStake2();
                            }}
                            disabled={loadingUn}
                            className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              {loadingUn ? (
                                <>
                                  <div
                                    class='spinner-border text-light spinner-border-sm'
                                    role='status'>
                                    <span class='sr-only'>Loading...</span>
                                  </div>
                                  &nbsp; Processing transaction...
                                </>
                              ) : (
                                <span className='mx-5 space-x-3'>Withdraw</span>
                              )}
                            </span>
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                )
              ) : (
                <div className='text-center'>
                  {chains.map(x => (
                    <button
                      disabled={!switchNetwork || x.id === chain?.id}
                      key={x.id}
                      className='flex max-w-4xl btn font-para text-lg m-auto mt-5 border-0 fw-bold items-center  font-para py-3 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
                      onClick={() => switchNetwork?.(x.id)}>
                      Switch {x.name} &nbsp;
                      {isLoading && pendingChainId === x.id && (
                        <div
                          class='spinner-border text-light spinner-border-sm'
                          role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                      )}
                    </button>
                  ))}
                </div>
              )
            ) : (
              <div
                onClick={open}
                className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                  <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                    <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                      Connect Wallet
                    </div>
                  </div>
                </span>
              </div>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Pool;
