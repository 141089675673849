/** @format */

import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

const Governance = () => {
  const [totalProposals, setTotalProposals] = useState(0);
  const [proposals, setProposals] = useState();
  const baseUrl = "https://snapshot.org/#/L2R.eth/proposal/";
  const now = moment();

  useEffect(() => {
    axios({
      method: "post",
      url: "https://hub.snapshot.org/graphql",
      data: {
        query: `
                    query Proposals {
                        proposals(
                          first: 20,
                          skip: 0,
                          where: {
                            space_in: ["L2R.eth"],
                           
                          },
                          orderBy: "created",
                          orderDirection: desc
                        ) {
                          id
                          title
                          body
                          choices
                          start
                          end
                          snapshot
                          state
                          author
                          space {
                            id
                            name
                          }
                        }
                      }`,
      },
    }).then(
      res => {
        console.log(res.data.data.proposals);
        setProposals(res.data.data.proposals);

        setTotalProposals(res.data.data.proposals.length);
      },
      error => {},
    );
  }, []);

  function ProposalsLink(url) {
    window.open(url, "_blank").focus();
  }
  React.useEffect(() => {
    document.title = "L2reum - Governance";
  }, []);

  return (
    <div className='py-10  flex-grow w-full md:max-w-[65%] lg:max-w-[80%] ms-auto   relative pt-5 min-h-screen'>
      <div className='container m-auto px-6 text-gray-500 md:px-10  '>
        <div className='  '>
          <div className='row'>
            <div className='col-md-12 pl-2'>
              <h1 className=' font-weight  font-head bg-gradient-to-br  from-[#57546C] to-[#9d9d9e] text-transparent bg-clip-text'>
                Governance
              </h1>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-lg-4'>
              <div className='tile   shadow-xl m-2 mr-4 font-head'>
                <h3 className=' '>0</h3>
                <h4 className='bg-gradient-to-br  from-[#57546C] to-[#9d9d9e] text-transparent bg-clip-text text-lg'>
                  Voting Addresses
                </h4>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='tile   shadow-xl m-2 mr-4 font-head'>
                <h3 className='font-weight-bold'>0</h3>
                <h4 className='bg-gradient-to-br  from-[#57546C] to-[#9d9d9e] text-transparent bg-clip-text text-lg'>
                  Voting Delegated
                </h4>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='tile   shadow-xl m-2 mr-4  font-head'>
                <h3 className='font-weight-bold pr-5'>{totalProposals}</h3>
                <h4 className='bg-gradient-to-br  from-[#57546C] to-[#9d9d9e] text-transparent bg-clip-text text-lg'>
                  Proposals &nbsp;
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container proposals mt-5 mb-10'>
        <div className=' shadow-xl caarss rounded-t-lg overflow-hidden'>
          <ul className='list-group list-group-flush bg-transparent '>
            <li className='list-group-item bg-gradient-to-br  from-[#7e7b86] to-[#9d9d9e]   text-xl border-0  font-semibold  p-3 pl-4 font-head '>
              Recent Proposals
            </li>
            {proposals ? (
              <React.Fragment>
                {proposals.map((key, index) => {
                  return (
                    <React.Fragment>
                      {/* {console.log(key.title)} */}
                      <li
                        className='list-group-item p-3 pb-4 pl-5 proposalsLink bg-transparent'
                        key={index}>
                        <span
                          className='font-weight-bold fs-20 link'
                          onClick={() => {
                            ProposalsLink(baseUrl + key.id);
                          }}>
                          {key.title}
                        </span>
                        <span className='d-block mt-1'>
                          <span
                            className={`badge ${
                              now.diff(moment.unix(key.end), "hours") > 0
                                ? "badge-success"
                                : "badge-warning"
                            }`}>
                            {now.diff(moment.unix(key.end), "hours") > 0
                              ? "Closed"
                              : "In Progress"}
                          </span>
                          &nbsp;{" "}
                          <span className='text-muted'>
                            #{totalProposals - index}{" "}
                            <i className='fa fa-circle'></i> &nbsp;
                            {moment.unix(key.start).format("MMMM Do, YYYY")}
                            &nbsp; - &nbsp;
                            {moment.unix(key.end).format("MMMM Do, YYYY")}
                          </span>
                        </span>
                      </li>
                    </React.Fragment>
                  );
                })}
                {Object.keys(proposals).length === 0 && (
                  <React.Fragment>
                    <div className='p-5 m-auto text-center text-base'>
                      <h5>Oops, we can't find any proposal.</h5>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <>
                <div className='text-center mt-4 mb-4'>
                  <div className='spinner-border' role='status'>
                    <span className='sr-only'></span>
                  </div>
                  <br />
                  Please wait, <br /> only for you.
                </div>
                {/* <div class="lds-ring mt-5 mb-5"><div></div><div></div><div></div><div></div></div> */}
              </>
            )}
          </ul>
        </div>
      </div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        // xmlns:svgjs='http://svgjs.dev/svgjs'
        className=' absolute bottom-0 w-full -z-50'
        width={1440}
        height={250}
        preserveAspectRatio='none'
        viewBox='0 0 1440 250'>
        <g mask='url("#SvgjsMask1006")' fill='none'>
          <rect
            width={1440}
            height={250}
            x={0}
            y={0}
            fill='rgba(255, 255, 255, 0)'
          />
          <path
            d='M26 250L276 0L364.5 0L114.5 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M278.6 250L528.6 0L782.1 0L532.1 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M504.20000000000005 250L754.2 0L884.7 0L634.7 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M715.8000000000001 250L965.8000000000001 0L1261.3000000000002 0L1011.3000000000001 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M1401 250L1151 0L1092.5 0L1342.5 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M1178.4 250L928.4000000000001 0L700.4000000000001 0L950.4000000000001 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M936.8 250L686.8 0L418.29999999999995 0L668.3 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M710.1999999999999 250L460.19999999999993 0L143.69999999999993 0L393.69999999999993 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M1304.192611426263 250L1440 114.19261142626311L1440 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M0 250L135.8073885737369 250L 0 114.19261142626311z'
            fill='url("#SvgjsLinearGradient1008")'
          />
        </g>
        <defs>
          <mask id='SvgjsMask1006'>
            <rect width={1440} height={250} fill='#ffffff' />
          </mask>
          <linearGradient
            x1='0%'
            y1='100%'
            x2='100%'
            y2='0%'
            id='SvgjsLinearGradient1007'>
            <stop stopColor='rgba(107, 110, 118, 0.2)' offset={0} />
            <stop
              stopOpacity={0}
              stopColor='rgba(107, 110, 118, 0.2)'
              offset='0.66'
            />
          </linearGradient>
          <linearGradient
            x1='100%'
            y1='100%'
            x2='0%'
            y2='0%'
            id='SvgjsLinearGradient1008'>
            <stop stopColor='rgba(107, 110, 118, 0.2)' offset={0} />
            <stop
              stopOpacity={0}
              stopColor='rgba(107, 110, 118, 0.2)'
              offset='0.66'
            />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Governance;
