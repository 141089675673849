/** @format */

import { IoCopyOutline } from "react-icons/io5";
import React, { useState, useEffect } from "react";
import Faqs from "../utils/Faqs";
import { toast } from "react-toastify";
import numbro from "numbro";
import { utils } from "ethers";
import { useWeb3Modal } from "@web3modal/react";
import NumberFormat from "react-number-format";

import { useAccount, useSwitchNetwork, useEnsName, useNetwork } from "wagmi";
import Web3 from "web3";
import { ErcABI, ErcContractAddress } from "../Contracts/Abi";
import useBalance from "../Contracts/useBalance";
import CountUp from "react-countup";
import { Link } from "react-router-dom";

const Stake = () => {
  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text successfully copied to clipboard:", text);
      })
      .catch(err => {
        console.error("Unable to copy text to clipboard:", err);
      });
  }

  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { open, close } = useWeb3Modal();
  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();
  const web3 = new Web3(Web3.givenProvider || "http://localhost:3000/");
  function truncate(str, n) {
    return str.length > n ? str.slice(0, 4) + "..." + str.slice(-4) : str;
  }
  const [bnbAmount, setBnbAmount] = useState(null);
  const [reward, setReward] = useState(null);
  const [balance, setBalance] = useState(null);
  const [btn, setBtn] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingUn, setloadingUn] = useState(false);

  const getFullDisplayBalance = (balance, format = {}) => {
    return numbro(utils.formatEther(balance)).format(Object.assign({}, format));
  };
  const [selected, setSelected] = useState(1);
  const handleButton = buttonId => {
    setSelected(buttonId);
    setBnbAmount("");
  };
  const bal = () => {
    if (address) {
      web3.eth.getBalance(address).then(balance => {
        setBalance(getFullDisplayBalance(balance));
      });
    }
  };
  const [yBNBbalance] = useBalance(
    "0x99B4D0b0a078AD86B3867C3822895E9705f7d56c",
    18,
  );

  bal();

  function calcPerc(amount) {
    setBnbAmount((balance / 100) * amount);
  }
  function calcPerc1(amount) {
    setBnbAmount((yBNBbalance / 100) * amount);
  }
  // console.log(bnbAmount);
  function purchaseSuccessful() {
    setBtn(false);
    toast.success("You have successfully deposit!");
    setBnbAmount("");
  }

  function purchaseFailed() {
    setBtn(false);
    toast.error("Unable to handle your request, try again!");
  }
  const handleDeposit = async () => {
    try {
      console.log(bnbAmount, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }

      if (!bnbAmount || isNaN(bnbAmount) || bnbAmount <= 0) {
        setBtn(false);
        return toast.error("Amount should be a valid number greater than 0");
      }

      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();
      const contract = new web3.eth.Contract(ErcABI, ErcContractAddress);
      const invest = await contract.methods.deposit().send({
        from: address,
        value: web3.utils.toWei(String(bnbAmount), "ether"),
        // gas: 1000000,
        gasPrice: rpcGasPrice,
      });

      if (invest) {
        purchaseSuccessful();
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };

  const handleUnStake = async () => {
    if (address) {
      if (bnbAmount != "" && bnbAmount != null && bnbAmount != 0) {
        try {
          setloadingUn(true);
          const rpcGasPrice = await web3.eth.getGasPrice();
          const contract = new web3.eth.Contract(ErcABI, ErcContractAddress);
          const withdraw = contract.methods
            .withdraw(web3.utils.toWei(String(bnbAmount), "ether"))
            .send({
              from: address,
              // gas: 1000000,
              gasPrice: rpcGasPrice,
            })
            .then(res => {
              setloadingUn(false);
              toast.success("Successfully UnStake");
              setBnbAmount("");
            })
            .catch(er => {
              setloadingUn(false);
              setBnbAmount("");
              toast.error(
                "Something went wrong, you can ask in telegram group about this issue",
              );
            });
        } catch (error) {
          setBtn(false);
          // console.log(5, error);
        }
      } else {
        setloadingUn(false);
        toast.error("Amount should not be empty");
      }
    } else {
      setloadingUn(false);
      toast.info(
        "Unable to detect any wallet, please connect metamask or trust wallet",
      );
    }
  };

  const handleHarvest = async () => {
    setloading(true);
    const rpcGasPrice = await web3.eth.getGasPrice();
    const contract = new web3.eth.Contract(ErcABI, ErcContractAddress);
    const harvest = contract.methods
      .harvest()
      .send({
        from: address,
        value: web3.utils.toWei("0", "ether"),
        // gas: 1000000,
        gasPrice: rpcGasPrice,
      })
      .then(res => {
        setloading(false);
        toast.success("Successfully Harvest");
      })
      .catch(er => {
        setloading(false);
        toast.error(
          "Something went wrong, you can ask in telegram group about this issue",
        );
      });
  };

  const handlePendingReward = async () => {
    const contract = new web3.eth.Contract(ErcABI, ErcContractAddress);
    const pendingReward = contract.methods
      .pendingReward(address)
      .call()
      .then(res => {
        setReward(getFullDisplayBalance(res));
        // console.log(res);
      })
      .catch(er => {});
  };

  useEffect(() => {
    if (chain?.id === 56) {
      handlePendingReward();
      const interval = setInterval(() => {
        handlePendingReward();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [address]);

  React.useEffect(() => {
    document.title = "L2reum - LRT Staking";
  }, []);

  // console.log("earn ====>", earn);
  return (
    <div className='container m-auto  text-gray-500   '>
      <div className='row'>
        <div className='flex switch justify-center gap-7 mb-4'>
          <Link to='/'>
            {" "}
            <button>
              LRT Staking
              <div id='clip'>
                <div id='leftTop' className='corner' />
                <div id='rightBottom' className='corner' />
                <div id='rightTop' className='corner' />
                <div id='leftBottom' className='corner' />
              </div>
              <span id='rightArrow' className='arrow' />
              <span id='leftArrow' className='arrow' />
            </button>
          </Link>{" "}
          <Link to='/pool'>
            {" "}
            <button>
              Trenches Pools
              <div id='clip'>
                <div id='leftTop' className='corner' />
                <div id='rightBottom' className='corner' />
                <div id='rightTop' className='corner' />
                <div id='leftBottom' className='corner' />
              </div>
              <span id='rightArrow' className='arrow' />
              <span id='leftArrow' className='arrow' />
            </button>
          </Link>
        </div>
      </div>
      <h1 className='typography-brand-heading-1 max-w-[16ch] m-auto animate-appear-slide-up text-white relative text-center'>
        L2R lets you stake
        <span
          className='mt-1 block overflow-hidden rounded-bl-lg rounded-tr-lg bg-[#33144A] align-middle transition-[width] duration-1000 xs:ml-4 xs:mt-0 xs:inline-block'
          style={{ height: 53, width: 125 }}>
          <span
            className='flex flex-col items-start text-[#E2107B] transition-transform duration-1000 '
            style={{ transform: "translateY(calc(0px))" }}>
            <span className='px-2 leading-[125%] xs:px-3'>BNB</span>
          </span>
        </span>
        to get daily
      </h1>
      <h3 className='typography-brand-heading-3 max-w-[28ch] m-auto animate-appear-slide-up text-gray-400 text-center mt-3'>
        Stake any amount and grow your balance with DeFi 24h redemption.
      </h3>
      <div className='card_main max-w-2xl mb-4 gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]   mt-4 m-auto '>
        {isConnected && (
          <div
            class=' flex justify-center  font-semibold text-sm items-center cursor-pointer'
            onClick={open}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill='currentColor'
              class='bi bi-wallet2 mr-2'
              viewBox='0 0 16 16'>
              <path d='M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z' />
            </svg>{" "}
            {truncate(`${ensName ? `${ensName} (${address})` : address}`, 8)}
          </div>
        )}
        {/* <div className='  flex  my-5   justify-around font-head'>
            <h3 className=' block  font-medium text-black text-2xl'>
              Restake{" "}
            </h3>
            <h3 className='  font-bold text-2xl flex flex-col justify-end items-end'>
              <span>
                {reward ? (
                  <span>
                    <CountUp
                      delay={2}
                      start={0}
                      end={parseFloat(reward).toFixed(2)}
                    />{" "}
                    yBNB
                  </span>
                ) : (
                  "0 yBNB"
                )}
              </span>
              <button
                onClick={handleHarvest}
                disabled={loading || yBNBbalance > 0 ? false : true}
                className='flex max-w-4xl btn font-para text-lg  border-0 fw-bold items-center  font-para py-2 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                {loading ? (
                  <>
                    <div
                      class='spinner-border text-light spinner-border-sm'
                      role='status'>
                      <span class='sr-only'>Loading...</span>
                    </div>
                    &nbsp; Processing transaction...
                  </>
                ) : (
                  "Harvest"
                )}
              </button>
            </h3>
          </div> */}

        {isConnected ? (
          <React.Fragment>
            {chain?.id === 56 ? (
              <div className='mt-5'>
                <div className='w-3/4 flex items-center m-auto  '>
                  <div
                    className={`btn_buy  absolute left-0 ${
                      selected === 1
                        ? "selected transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]"
                        : "not-selected bg-[#3017488e]"
                    }`}
                    onClick={() => handleButton(1)}>
                    Deposit{" "}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      fill='currentColor'
                      className='bi bi-plus'
                      viewBox='0 0 16 16'>
                      <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                    </svg>
                  </div>
                  <div
                    className={`btn_sell absolute right-0 ${
                      selected === 2
                        ? "selected transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]"
                        : "not-selected bg-[#3017488e]"
                    }`}
                    onClick={() => handleButton(2)}>
                    UnStake{" "}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      fill='currentColor'
                      className='bi bi-dash'
                      viewBox='0 0 16 16'>
                      <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8' />
                    </svg>
                  </div>
                </div>
                {selected === 1 ? (
                  <React.Fragment>
                    <div className='my-5 '>
                      <div class='flex items-center rounded-md  border-1 border-pink-500   '>
                        <NumberFormat
                          value={bnbAmount || ""}
                          onChange={e => setBnbAmount(e.target.value)}
                          allowNegative={false}
                          thousandSeparator
                          isNumericString
                          placeholder='0.0'
                          className={`flex-1 p-2 border-r border-pink-500 text-2xl text-white bg-transparent `}
                        />
                        <span className='p-[12px] flex items-center'>
                          <button
                            onClick={() => calcPerc(100)}
                            className=' select-none disabled:cursor-not-allowed rounded  py-2 px-1  text-center align-middle font-para text-xs font-bold uppercase text-gray-100 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type='button'>
                            Max
                          </button>
                        </span>
                      </div>

                      <p className='text-sm w-full  text-center m-auto mt-1 text-white'>
                        Balance:{" "}
                        {balance
                          ? parseFloat(balance).toFixed(3) + " BNB"
                          : "0"}
                      </p>
                    </div>
                    <div className='text-center'>
                      <button
                        onClick={() => {
                          handleDeposit();
                        }}
                        disabled={btn}
                        className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                        <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                          <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                            {btn ? (
                              <>
                                <div
                                  class='spinner-border text-light spinner-border-sm'
                                  role='status'>
                                  <span class='sr-only'>Loading...</span>
                                </div>
                                &nbsp; Processing transaction...
                              </>
                            ) : (
                              <span className='mx-4'>Stake BNB</span>
                            )}
                          </div>
                        </div>
                      </button>
                    </div>

                    <div className='  flex  mt-5  items-center justify-between sm:w-2/4 w-full m-auto  font-head'>
                      <p className='typography-brand-heading-3  text-gray-300'>
                        You will receive
                      </p>
                      <p className=' typography-brand-heading-3  text-yellow-300  flex flex-col justify-end items-end'>
                        {bnbAmount ? bnbAmount : "0.00"} yBNB
                      </p>
                    </div>
                    <div className='  flex  mt-3  items-center justify-between sm:w-2/4 w-full m-auto font-head'>
                      <p className='typography-brand-heading-3  text-gray-300'>
                        Exchange rate: 1 BNB
                      </p>
                      <p className=' typography-brand-heading-3  text-yellow-300  flex flex-col justify-end items-end'>
                        1 yBNB
                      </p>
                    </div>
                    <div className='  flex  mt-3  items-center justify-between sm:w-2/4 w-full m-auto font-head'>
                      <p className='typography-brand-heading-3  text-gray-300'>
                        Max transaction cost
                      </p>
                      <p className=' typography-brand-heading-3  text-yellow-300  flex flex-col justify-end items-end'>
                        $0.36
                      </p>
                    </div>
                    <div className='  flex  mt-3  items-center justify-between sm:w-2/4 w-full m-auto font-head'>
                      <p className='typography-brand-heading-3  text-gray-300'>
                        Annual percentage rate
                      </p>
                      <p className=' typography-brand-heading-3  text-yellow-300  flex flex-col justify-end items-end'>
                        10%
                      </p>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className='my-5 '>
                      <div class='flex items-center rounded-md  border-1 border-pink-500   '>
                        <NumberFormat
                          value={bnbAmount || ""}
                          onChange={e => setBnbAmount(e.target.value)}
                          allowNegative={false}
                          thousandSeparator
                          isNumericString
                          placeholder='0.0'
                          className={`flex-1 p-2 border-r border-pink-500 text-2xl text-white bg-transparent `}
                        />
                        <span className='p-[12px] flex items-center'>
                          <button
                            onClick={() => calcPerc(100)}
                            className=' select-none disabled:cursor-not-allowed rounded  py-2 px-1  text-center align-middle font-para text-xs font-bold uppercase text-gray-100 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type='button'>
                            Max
                          </button>
                        </span>
                      </div>
                      <h5 className='text-sm w-full  text-center m-auto mt-1 text-white'>
                        Balance:{" "}
                        {yBNBbalance
                          ? parseFloat(yBNBbalance).toFixed(3) + " yBNB"
                          : "0"}
                      </h5>
                    </div>
                    <div className='text-center'>
                      <button
                        onClick={() => {
                          handleUnStake();
                        }}
                        disabled={loadingUn}
                        className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
                        <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                          <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                            {loadingUn ? (
                              <>
                                <div
                                  class='spinner-border text-light spinner-border-sm'
                                  role='status'>
                                  <span class='sr-only'>Loading...</span>
                                </div>
                                &nbsp; Processing transaction...
                              </>
                            ) : (
                              <span className='mx-4'>UnStake</span>
                            )}
                          </div>
                        </div>
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            ) : (
              <div className='text-center'>
                {chains.map(x => (
                  <button
                    disabled={!switchNetwork || x.id === chain?.id}
                    key={x.id}
                    className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'
                    onClick={() => switchNetwork?.(x.id)}>
                    <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                      <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                        Switch {x.name} &nbsp;
                        {isLoading && pendingChainId === x.id && (
                          <div
                            class='spinner-border text-light spinner-border-sm'
                            role='status'>
                            <span class='sr-only'>Loading...</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </React.Fragment>
        ) : (
          <div
            onClick={open}
            className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
              <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
                <div className='disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
                  Connect Wallet
                </div>
              </div>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Stake;
