/** @format */

import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Base = ({ children }) => {
  return (
    <React.Fragment>
      <div className='relative flex min-h-screen w-screen px-[11px] py-[12px] sm:py-[11px] h-auto sm:h-screen'>
        <div className='relative flex min-h-full w-full flex-1 flex-col rounded-md gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]  '>
          <div className='relative z-10 flex flex-1 flex-col pb-16 h-auto sm:h-full bg-img'>
            <div className='flex flex-1 sm:overflow-hidden flex-col'>
              <div className='sticky top-[14px] z-10 sm:top-[13px]'>
                <div className='relative'>
                  <Header />

                  <div className='absolute inset-0 top-[-12px] -z-10 transition-opacity duration-300 opacity-0 backdrop-blur-[12px]' />
                </div>
              </div>
              <main className='relative  h-full  pt-6    lg:pt-0 overflow-hidden'>
                {children}
              </main>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Base;
